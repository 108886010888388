import React, { useState } from "react";

const PricingContainer = () => {
  // States to toggle between Monthly and Yearly plans and selected card
  const [isYearly, setIsYearly] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Pricing data for Monthly and Yearly
  const pricingData = {
    basic: { monthly: 9.99, yearly: 99.99 },
    pro: { monthly: 19.99, yearly: 199.99 },
    premium: { monthly: 49.99, yearly: 499.99 },
  };

  return (
    <div className="pricing-section">
      <h2 className="pricing-heading">Choose Your Plan</h2>

      {/* Toggle for Monthly/Yearly pricing */}
      <div className="toggle-container">
        <span>Monthly</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={isYearly}
            onChange={() => setIsYearly(!isYearly)}
          />
          <span className="slider"></span>
        </label>
        <span>Yearly (Save 20%)</span>
      </div>

      <div className="pricing-container">
        <div
          className={`pricing-card ${selectedPlan === "basic" ? "selected" : ""}`}
          onClick={() => setSelectedPlan("basic")}
        >
          <h3>Basic Plan</h3>
          <p className="price">${isYearly ? pricingData.basic.yearly : pricingData.basic.monthly}</p>
          <ul>
            <li>10 Projects</li>
            <li>5 GB Storage</li>
            <li>Email Support</li>
          </ul>
          <button>Select Plan</button>
        </div>

        <div
          className={`pricing-card highlighted ${selectedPlan === "pro" ? "selected" : ""}`}
          onClick={() => setSelectedPlan("pro")}
        >
          <h3>Pro Plan</h3>
          <p className="price">${isYearly ? pricingData.pro.yearly : pricingData.pro.monthly}</p>
          <ul>
            <li>50 Projects</li>
            <li>50 GB Storage</li>
            <li>Priority Support</li>
          </ul>
          <button>Select Plan</button>
        </div>

        <div
          className={`pricing-card ${selectedPlan === "premium" ? "selected" : ""}`}
          onClick={() => setSelectedPlan("premium")}
        >
          <h3>Premium Plan</h3>
          <p className="price">${isYearly ? pricingData.premium.yearly : pricingData.premium.monthly}</p>
          <ul>
            <li>Unlimited Projects</li>
            <li>200 GB Storage</li>
            <li>24/7 Support</li>
          </ul>
          <button>Select Plan</button>
        </div>
      </div>
    </div>
  );
};

export default PricingContainer;
