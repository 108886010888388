import React, { useState } from 'react';
import { Navigation } from './components/navigation'; // Adjust the path if necessary
import { Header } from './components/header'; 
import  { FooterComponent }  from './components/footer'; 
import { Newsletter } from './components/Newsletter';// Adjust the path if necessary
import './new.css';
import { Carousel } from "./components/carousel";
import  PricingContainer from "./components/pricing-container";
import  HowCanWeHelp from "./components/benefit";



import './overlay.css';
// src/Checklist.js
//import FadeInOnScroll from './components/FadeInOnScroll';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';


// Card Component
function Card({ title, coverImage, characterImage, onClick }) {
  return (
    <div className="card" onClick={onClick}>
      <img src={coverImage} alt={`${title} Cover`} className="cover-image" />
      <h3 className="title">{title}</h3>
      <img src={characterImage} alt={`${title} Character`} className="character" />
    </div>
  );
}




// FeatureItem Component
function FeatureItem({ id, icon, label, description, isSelected, onClick }) {
  return (
    <div
      className={`feature-item ${isSelected ? 'selected' : ''}`} // Apply 'selected' class if isSelected is true
      onClick={onClick} // Handle card click
      style={{ cursor: 'pointer' }} // Add cursor to indicate it's clickable
    >
      <label htmlFor={id} className="feature-label">
        <img src={icon} alt={`${label} Icon`} className="feature-icon" />
        <div className="separator-vertical"></div>
        <div className="label-container">
          <h2 className="label">{label}</h2>
          <p className="feature-description">{description}</p>
        </div>
      </label>
    </div>
  );
}


// FeatureList Component

const checklists = {
  feature1: [
    "Warm-Up", "Dynamic Stretching", "Arm Circles", "Leg Swings", 
    "Jumping Jacks", "Light Jogging", "HIIT", "Burpees", 
    "Mountain Climbers", "Jump Squats", "High Knees", "Sprint Intervals", 
    "Steady-State Cardio", "Running", "Cycling", "Rowing Machine", 
    "Elliptical Machine", "Stair Climbing", "Bodyweight Cardio", 
    "Jump Rope", "Plyometric Push-Ups", "Skater Jumps", "Tuck Jumps", 
    "Star Jumps", "Low-Impact Cardio", "Brisk Walking", "Swimming", 
    "Low-Intensity Cycling", "Cool Down", "Stretching"
  ],
  // Add other features with their checklists...
};

function FeatureList({ title, items }) {
  const [selectedFeature, setSelectedFeature] = useState(null);

  const handleSelectFeature = (id) => {
    if (selectedFeature === id) {
      setSelectedFeature(null);
    } else {
      setSelectedFeature(id);
      downloadPDF(id);
    }
  };

  const downloadPDF = (id) => {
    const doc = new jsPDF();
    const selectedItem = items.find(item => item.id === id);
  
    if (selectedItem) {
      const checklistItems = checklists[selectedItem.id];
  
      // Prepare table data with empty strings where checkboxes will be drawn
      const tableData = checklistItems.map(item => [item, '']); // Leave space for checkboxes
      // Set headers
      const headers = [['Task', 'Checkbox']];
  
      // Use autoTable to create the table
      autoTable(doc, {
        head: headers,
        body: tableData,
        startY: 20, // Adjust to remove extra space above
        styles: { overflow: 'linebreak' },
        columnStyles: {
          0: { cellWidth: 120 }, // Set width for the first column (Task)
          1: { cellWidth: 40 },  // Set width for the second column (Checkbox)
        },
        theme: 'grid', // Optional: Use grid theme for better visibility
        margin: { left: (doc.internal.pageSize.getWidth() - 160) / 2 },
        didDrawCell: function (data) {
          // Only draw checkbox for body rows (not header row)
          if (data.section === 'body' && data.column.index === 1) {
            const checkboxX = data.cell.x + data.cell.padding('left'); // X position of the checkbox
            const checkboxY = data.cell.y + data.cell.height / 2 - 2; // Y position, adjust for centering
  
            // Draw a small square (checkbox)
            doc.rect(checkboxX, checkboxY, 5, 5); // Rectangle with 5x5 size for checkbox
          }
        }
      });
  
      doc.save(`${selectedItem.label} Checklist.pdf`);
    }
  };
  

  return (
    <div className="feature-list-container">
      <div className="feature-heading">
        <h1 className="bold-heading">{title}</h1>
      </div>
      <div className="feature-columns">
        {items.map((item) => (
          <FeatureItem
            key={item.id}
            {...item}
            isSelected={selectedFeature === item.id}
            onClick={() => handleSelectFeature(item.id)}
          />
        ))}
      </div>
    </div>
  );
}


// Checklist Component
function Checklist() {
  const cards = [
    { title: 'Fitness Routine', coverImage: 'images/fitness-routine.jpg', characterImage: 'images/fit.png' },
    { title: 'Personal Finance', coverImage: 'images/personal-finance.jpg', characterImage: 'images/finance.png' },
    { title: 'Health & Wellness', coverImage: 'images/health-wellness.jpg', characterImage: 'images/health.png' },
    { title: 'Work Productivity', coverImage: 'images/work-productivity.jpg', characterImage: 'images/work.png' },
    { title: 'Travel', coverImage: 'images/travel.jpg', characterImage: 'images/travell.png' },
    { title: 'Food & Nutrition', coverImage: 'images/food-nutrition.jpg', characterImage: 'images/food.png' },
    { title: 'Gardening', coverImage: 'images/gardening.jpg', characterImage: 'images/garden.png' },
    { title: 'Home Management', coverImage: 'images/home-management.jpg', characterImage: 'images/home.png' },
    { title: 'Time Management', coverImage: 'images/time-management.jpg', characterImage: 'images/time.png' },
    { title: 'Personal Growth', coverImage: 'images/personal-growth.jpg', characterImage: 'images/growth.png' },
    { title: 'Music Practice', coverImage: 'images/music-practice.jpg', characterImage: 'images/music-practice-character.png' },
    { title: 'Self Care', coverImage: 'images/self-care.jpg', characterImage: 'images/self-care-character.png' },
    { title: 'Goal Setting', coverImage: 'images/goal-setting.jpg', characterImage: 'images/goal-setting-character.png' },
    { title: 'Stress Management', coverImage: 'images/stress-management.jpg', characterImage: 'images/stress-management-character.png' },
    { title: 'Cooking & Baking', coverImage: 'images/cooking-baking.jpg', characterImage: 'images/cooking-baking-character.png' },
    { title: 'Professional Networking', coverImage: 'images/professional-networking.jpg', characterImage: 'images/professional-networking-character.png' },
{ title: 'Creative Projects', coverImage: 'images/creative-projects.jpg', characterImage: 'images/creative-projects-character.png' },
{ title: 'Mental Health', coverImage: 'images/mental-health.jpg', characterImage: 'images/mental-health-character.png' },
{ title: 'Leadership Skills', coverImage: 'images/leadership-skills.jpg', characterImage: 'images/leadership-skills-character.png' },
{ title: 'Public Speaking', coverImage: 'images/public-speaking.jpg', characterImage: 'images/public-speaking-character.png' },
{ title: 'Language Learning', coverImage: 'images/Language-learning.jpg', characterImage: 'images/learning-development-character.png' },
{ title: 'Career Development', coverImage: 'images/career-development.jpg', characterImage: 'images/career-development-character.png' },
{ title: 'Project Management', coverImage: 'images/project-management.jpg', characterImage: 'images/project-management-character.png' },
{ title: 'Financial Planning', coverImage: 'images/financial-planning.jpg', characterImage: 'images/financial-planning-character.png' }

  ];

  const features = [
    { id: 'feature1', icon: 'images/overlay/cardio.gif', label: 'Cardio Workouts', description: 'Boost Your Endurance' },
    { id: 'feature2', icon: 'images/overlay/weightlifting.gif', label: 'Strength Training', description: 'Build Muscle Mass' },
    { id: 'feature3', icon: 'images/overlay/yoga.gif', label: 'Recovery', description: 'Enhance Flexibility' },
    { id: 'feature4', icon: 'images/overlay/schedule.gif', label: 'Workout Schedule', description: 'Plan Your Routine' },
    { id: 'feature5', icon: 'images/overlay/goal.gif', label: 'Fitness Goals', description: 'Track Your Progress' },
    { id: 'feature6', icon: 'images/overlay/personal-trainer.gif', label: 'Personal Training', description: 'Get Expert Guidance' },
    { id: 'feature7', icon: 'images/overlay/bodycombat.gif', label: 'Group Classes', description: 'Join Group Sessions' },
    { id: 'feature8', icon: 'images/overlay/pull-up.gif', label: 'Home Workouts', description: 'Exercise At Home' },
    { id: 'feature9', icon: 'images/overlay/gym.gif', label: 'Gym Workouts', description: 'Utilize Gym Facilities' },
    { id: 'feature10', icon: 'images/overlay/cycling.gif', label: 'Outdoor Activities', description: 'Enjoy Fresh Air' },
    { id: 'feature11', icon: 'images/overlay/sleep.gif', label: 'Recovery Techniques', description: 'Improve Rest Quality' },
    { id: 'feature12', icon: 'images/overlay/goal (1).gif', label: 'Fitness Challenges', description: 'Push Your Limits' },
  ];
  const features1 = [
    { id: 'feature1', icon: 'images/overlay/budget.gif', label: 'Monthly Budgeting', description: 'Manage Your Expenses' },
    { id: 'feature2', icon: 'images/overlay/savings.gif', label: 'Saving Goals', description: 'Set Your Financial Targets' },
    { id: 'feature3', icon: 'images/overlay/debt.gif', label: 'Debt Repayment', description: 'Plan Your Debt Payments' },
    { id: 'feature4', icon: 'images/overlay/investment.gif', label: 'Investment Planning', description: 'Grow Your Wealth' },
    { id: 'feature5', icon: 'images/overlay/tax.gif', label: 'Tax Preparation', description: 'Get Ready for Tax Season' },
    { id: 'feature6', icon: 'images/overlay/retirement.gif', label: 'Retirement Planning', description: 'Secure Your Future' },
    { id: 'feature7', icon: 'images/overlay/emergency.gif', label: 'Emergency Fund', description: 'Prepare for Emergencies' },
    { id: 'feature8', icon: 'images/overlay/accounting.gif', label: 'Expense Tracking', description: 'Monitor Your Spending' },
    { id: 'feature9', icon: 'images/overlay/insurance.gif', label: 'Insurance Policies', description: 'Protect Your Assets' },
    { id: 'feature10', icon: 'images/overlay/health-check.gif', label: 'Financial Health Check', description: 'Assess Your Financial Health' },
    { id: 'feature11', icon: 'images/overlay/credit.gif', label: 'Credit Score', description: 'Improve Your Credit Score' },
    { id: 'feature12', icon: 'images/overlay/financing.gif', label: 'Financial Goals', description: 'Your Financial Milestones' },
  ];
  
  const features2 = [
    { id: 'feature1', icon: 'images/overlay/exercise.gif', label: 'Daily Exercise', description: 'Maintain Your Physical Health' },
    { id: 'feature2', icon: 'images/overlay/eating.gif', label: 'Healthy Eating', description: 'Follow a Balanced Diet' },
    { id: 'feature3', icon: 'images/overlay/sleep.gif', label: 'Sleep Hygiene', description: 'Improve Your Sleep Patterns' },
    { id: 'feature4', icon: 'images/overlay/mental-health.gif', label: 'Mental Health', description: 'Care for Your Mental Wellbeing' },
    { id: 'feature5', icon: 'images/overlay/health-check.gif', label: 'Health Check-ups', description: 'Stay On Top of Your Health' },
    { id: 'feature6', icon: 'images/overlay/hydration.gif', label: 'Hydration', description: 'Keep Yourself Hydrated' },
    { id: 'feature7', icon: 'images/overlay/medication.gif', label: 'Medication Management', description: 'Manage Your Medications Properly' },
    { id: 'feature8', icon: 'images/overlay/hygiene.gif', label: 'Personal Hygiene', description: 'Maintain Your Personal Cleanliness' },
    { id: 'feature9', icon: 'images/overlay/stress.gif', label: 'Stress Management', description: 'Manage and Reduce Stress' },
    { id: 'feature10', icon: 'images/overlay/wellness.gif', label: 'Wellness Goals', description: 'Set and Achieve Your Wellness Goals' },
    { id: 'feature11', icon: 'images/overlay/preventive-care.gif', label: 'Preventive Care', description: 'Focus on Preventing Health Issues' },
    { id: 'feature12', icon: 'images/overlay/fitness-tracking.gif', label: 'Fitness Tracking', description: 'Monitor Your Fitness Progress' },
  ];
  const features3 = [
    { id: 'feature1', icon: 'images/overlay/work-tasks.gif', label: 'Daily Work Tasks', description: 'Organize and Complete Your Daily Tasks' },
    { id: 'feature2', icon: 'images/overlay/work-review.gif', label: 'Weekly Work Review', description: 'Evaluate and Reflect on Your Week' },
    { id: 'feature3', icon: 'images/overlay/project-management.gif', label: 'Project Management', description: 'Manage Your Projects Efficiently' },
    { id: 'feature4', icon: 'images/overlay/meeting-preparation.gif', label: 'Meeting Preparation', description: 'Prepare Effectively for Meetings' },
    { id: 'feature5', icon: 'images/overlay/email-management.gif', label: 'Email Management', description: 'Organize and Respond to Your Emails' },
    { id: 'feature6', icon: 'images/overlay/time-tracking.gif', label: 'Time Tracking', description: 'Keep Track of Time Spent on Tasks' },
    { id: 'feature7', icon: 'images/overlay/remote-work.gif', label: 'Remote Work', description: 'Manage Your Work from Anywhere' },
    { id: 'feature8', icon: 'images/overlay/office-organization.gif', label: 'Office Organization', description: 'Maintain a Well-Organized Workspace' },
    { id: 'feature9', icon: 'images/overlay/career-development.gif', label: 'Career Development', description: 'Focus on Advancing Your Career' },
    { id: 'feature10', icon: 'images/overlay/networking.gif', label: 'Networking', description: 'Build Professional Connections' },
    { id: 'feature11', icon: 'images/overlay/performance-review.gif', label: 'Performance Reviews', description: 'Track and Improve Your Performance' },
    { id: 'feature12', icon: 'images/overlay/task-prioritization.gif', label: 'Task Prioritization', description: 'Prioritize Your Most Important Tasks' },
  ];
  const features4 = [
    { id: 'feature1', icon: 'images/overlay/family-travel.gif', label: 'Family Travel', description: 'Plan Trips for the Entire Family' },
    { id: 'feature2', icon: 'images/overlay/low-cost-airline.gif', label: 'Low-Cost Airline Travel', description: 'Travel Affordably with Budget Airlines' },
    { id: 'feature3', icon: 'images/overlay/luggage.gif', label: 'Travel Baggage', description: 'Manage Your Luggage for Smooth Travel' },
    { id: 'feature4', icon: 'images/overlay/new-country.gif', label: 'New Country Travel', description: 'Explore a New Country' },
    { id: 'feature5', icon: 'images/overlay/new-city.gif', label: 'New City Travel', description: 'Discover a New City' },
    { id: 'feature6', icon: 'images/overlay/sightseeing.gif', label: 'Sightseeing', description: 'Visit Popular Tourist Attractions' },
    { id: 'feature7', icon: 'images/overlay/long-drive.gif', label: 'Long Car Drives', description: 'Enjoy Long Road Trips' },
    { id: 'feature8', icon: 'images/overlay/medical-emergency.gif', label: 'Medical Emergencies', description: 'Prepare for Health Emergencies While Traveling' },
    { id: 'feature9', icon: 'images/overlay/travel-with-babies.gif', label: 'Traveling with Babies', description: 'Tips for Traveling Comfortably with Babies' },
    { id: 'feature10', icon: 'images/overlay/elderly-travel.gif', label: 'Elderly Travel', description: 'Make Travel Comfortable for Seniors' },
    { id: 'feature11', icon: 'images/overlay/business-travel.gif', label: 'Business Travel', description: 'Efficient Planning for Work-Related Travel' },
    { id: 'feature12', icon: 'images/overlay/solo-travel.gif', label: 'Solo Travel', description: 'Enjoy the Freedom of Traveling Alone' },
  ];
  const features9 = [
    { id: 'feature1', icon: 'images/overlay/personal-goals.gif', label: 'Personal Goals', description: 'Define and Achieve Your Goals' },
    { id: 'feature2', icon: 'images/overlay/journaling.gif', label: 'Daily Journaling', description: 'Keep Track of Your Thoughts Daily' },
    { id: 'feature3', icon: 'images/overlay/reading-habits.gif', label: 'Reading Habits', description: 'Cultivate a Consistent Reading Routine' },
    { id: 'feature4', icon: 'images/overlay/meditation.gif', label: 'Meditation Practice', description: 'Enhance Your Mindfulness with Meditation' },
    { id: 'feature5', icon: 'images/overlay/public-speaking.gif', label: 'Public Speaking', description: 'Improve Your Speaking Skills' },
    { id: 'feature6', icon: 'images/overlay/time-management.gif', label: 'Time Management', description: 'Master the Art of Managing Time' },
    { id: 'feature7', icon: 'images/overlay/networking.gif', label: 'Networking', description: 'Build Strong Professional and Social Connections' },
    { id: 'feature8', icon: 'images/overlay/creative-projects.gif', label: 'Creative Projects', description: 'Work on and Explore Your Creativity' },
    { id: 'feature9', icon: 'images/overlay/self-reflection.gif', label: 'Self-Reflection', description: 'Reflect on Your Personal Growth' },
    { id: 'feature10', icon: 'images/overlay/learning-skills.gif', label: 'Learning New Skills', description: 'Continuously Acquire New Skills' },
    { id: 'feature11', icon: 'images/overlay/confidence-building.gif', label: 'Building Confidence', description: 'Boost Your Self-Confidence' },
    { id: 'feature12', icon: 'images/overlay/emotional-intelligence.gif', label: 'Emotional Intelligence', description: 'Enhance Your Emotional Awareness and Control' },
  ];
  
  const features5 = [
    { id: 'feature1', icon: 'images/overlay/meal-planning.gif', label: 'Weekly Meal Planning', description: 'Plan Your Meals for the Week' },
    { id: 'feature2', icon: 'images/overlay/grocery-shopping.gif', label: 'Grocery Shopping', description: 'Organize Your Shopping List' },
    { id: 'feature3', icon: 'images/overlay/cooking-preparation.gif', label: 'Cooking Preparation', description: 'Prepare for Cooking Efficiently' },
    { id: 'feature4', icon: 'images/overlay/healthy-eating.gif', label: 'Healthy Eating', description: 'Maintain a Nutritious Diet' },
    { id: 'feature5', icon: 'images/overlay/special-diets.gif', label: 'Special Diets', description: 'Plan for Specialized Diets' },
    { id: 'feature6', icon: 'images/overlay/food-storage.gif', label: 'Food Storage', description: 'Store Your Food Properly' },
    { id: 'feature7', icon: 'images/overlay/eating-out.gif', label: 'Eating Out', description: 'Make Smart Choices When Dining Out' },
    { id: 'feature8', icon: 'images/overlay/hydration.gif', label: 'Hydration', description: 'Keep Yourself Hydrated' },
    { id: 'feature9', icon: 'images/overlay/supplements.gif', label: 'Nutritional Supplements', description: 'Incorporate Supplements into Your Diet' },
    { id: 'feature10', icon: 'images/overlay/recipe-organization.gif', label: 'Recipe Organization', description: 'Organize Your Favorite Recipes' },
    { id: 'feature11', icon: 'images/overlay/seasonal-eating.gif', label: 'Seasonal Eating', description: 'Eat Seasonally for Better Health' },
    { id: 'feature12', icon: 'images/overlay/food-budgeting.gif', label: 'Food Budgeting', description: 'Plan and Stick to Your Food Budget' },
  ];
  const features6 = [
    { id: 'feature1', icon: 'images/overlay/vegetable-garden.gif', label: 'Vegetable Garden', description: 'Grow Fresh Vegetables at Home' },
    { id: 'feature2', icon: 'images/overlay/flower-garden.gif', label: 'Flower Garden', description: 'Create a Beautiful Flower Garden' },
    { id: 'feature3', icon: 'images/overlay/herb-garden.gif', label: 'Herb Garden', description: 'Cultivate Your Own Herb Garden' },
    { id: 'feature4', icon: 'images/overlay/indoor-plants.gif', label: 'Indoor Plants', description: 'Take Care of Indoor Plants' },
    { id: 'feature5', icon: 'images/overlay/seasonal-gardening.gif', label: 'Seasonal Gardening', description: 'Adapt Your Garden to Each Season' },
    { id: 'feature6', icon: 'images/overlay/garden-design.gif', label: 'Garden Design', description: 'Plan and Design Your Garden' },
    { id: 'feature7', icon: 'images/overlay/soil-preparation.gif', label: 'Soil Preparation', description: 'Prepare the Soil for Healthy Plants' },
    { id: 'feature8', icon: 'images/overlay/pest-control.gif', label: 'Pest Control', description: 'Keep Pests Away from Your Garden' },
    { id: 'feature9', icon: 'images/overlay/composting.gif', label: 'Composting', description: 'Create Your Own Compost' },
    { id: 'feature10', icon: 'images/overlay/watering-techniques.gif', label: 'Watering Techniques', description: 'Master Effective Watering Methods' },
    { id: 'feature11', icon: 'images/overlay/pruning-trimming.gif', label: 'Pruning and Trimming', description: 'Maintain Plants with Pruning and Trimming' },
    { id: 'feature12', icon: 'images/overlay/garden-tools.gif', label: 'Garden Tools', description: 'Use the Right Tools for Gardening' },
  ];
  const features7 = [
    { id: 'feature1', icon: 'images/overlay/daily-cleaning.gif', label: 'Daily Cleaning', description: 'Keep Your Home Clean Daily' },
    { id: 'feature2', icon: 'images/overlay/weekly-cleaning.gif', label: 'Weekly Cleaning', description: 'Deep Clean Your Home Every Week' },
    { id: 'feature3', icon: 'images/overlay/monthly-maintenance.gif', label: 'Monthly Maintenance', description: 'Perform Essential Monthly Maintenance' },
    { id: 'feature4', icon: 'images/overlay/seasonal-maintenance.gif', label: 'Seasonal Maintenance', description: 'Prepare Your Home for Each Season' },
    { id: 'feature5', icon: 'images/overlay/home-security.gif', label: 'Home Security', description: 'Ensure the Security of Your Home' },
    { id: 'feature6', icon: 'images/overlay/decluttering.gif', label: 'Decluttering', description: 'Organize and Declutter Your Space' },
    { id: 'feature7', icon: 'images/overlay/moving-homes.gif', label: 'Moving Homes', description: 'Plan and Execute a Home Move' },
    { id: 'feature8', icon: 'images/overlay/hosting-guests.gif', label: 'Hosting Guests', description: 'Get Ready to Host Guests' },
    { id: 'feature9', icon: 'images/overlay/home-repairs.gif', label: 'Home Repairs', description: 'Fix and Maintain Your Home' },
    { id: 'feature10', icon: 'images/overlay/energy-efficiency.gif', label: 'Energy Efficiency', description: 'Optimize Your Home’s Energy Usage' },
    { id: 'feature11', icon: 'images/overlay/appliance-maintenance.gif', label: 'Appliance Maintenance', description: 'Maintain and Service Your Appliances' },
    { id: 'feature12', icon: 'images/overlay/lawn-care.gif', label: 'Lawn Care', description: 'Take Care of Your Lawn' },
  ];
  const features8 = [
    { id: 'feature1', icon: 'images/overlay/daily-planning.gif', label: 'Daily Planning', description: 'Plan Your Day Effectively' },
    { id: 'feature2', icon: 'images/overlay/weekly-planning.gif', label: 'Weekly Planning', description: 'Organize Your Week for Success' },
    { id: 'feature3', icon: 'images/overlay/monthly-planning.gif', label: 'Monthly Planning', description: 'Set and Achieve Monthly Goals' },
    { id: 'feature4', icon: 'images/overlay/goal-setting.gif', label: 'Goal Setting', description: 'Set Clear and Achievable Goals' },
    { id: 'feature5', icon: 'images/overlay/prioritization.gif', label: 'Prioritization', description: 'Focus on Your Most Important Tasks' },
    { id: 'feature6', icon: 'images/overlay/time-tracking.gif', label: 'Time Tracking', description: 'Track Your Time for Better Efficiency' },
    { id: 'feature7', icon: 'images/overlay/scheduling.gif', label: 'Scheduling', description: 'Organize Your Time Effectively' },
    { id: 'feature8', icon: 'images/overlay/task-management.gif', label: 'Task Management', description: 'Manage and Track Your Tasks' },
    { id: 'feature9', icon: 'images/overlay/delegation.gif', label: 'Delegation', description: 'Delegate Tasks Efficiently' },
    { id: 'feature10', icon: 'images/overlay/productivity-tools.gif', label: 'Productivity Tools', description: 'Leverage Tools for Maximum Productivity' },
    { id: 'feature11', icon: 'images/overlay/time-blocking.gif', label: 'Time Blocking', description: 'Organize Your Day with Time Blocks' },
    { id: 'feature12', icon: 'images/overlay/avoiding-procrastination.gif', label: 'Avoiding Procrastination', description: 'Stay Focused and Beat Procrastination' },
  ];
  const features10 = [
    { id: 'feature1', icon: 'images/overlay/instrument-practice.gif', label: 'Instrument Practice', description: 'Daily Practice for Musical Mastery' },
    { id: 'feature2', icon: 'images/overlay/music-theory.gif', label: 'Music Theory', description: 'Understand the Fundamentals of Music' },
    { id: 'feature3', icon: 'images/overlay/sight-reading.gif', label: 'Sight Reading', description: 'Improve Your Sight Reading Skills' },
    { id: 'feature4', icon: 'images/overlay/ear-training.gif', label: 'Ear Training', description: 'Develop a Better Musical Ear' },
    { id: 'feature5', icon: 'images/overlay/performance-practice.gif', label: 'Performance Practice', description: 'Prepare for Performances with Confidence' },
    { id: 'feature6', icon: 'images/overlay/music-repertoire.gif', label: 'Music Repertoire', description: 'Build and Refine Your Repertoire' },
    { id: 'feature7', icon: 'images/overlay/recording-techniques.gif', label: 'Recording Techniques', description: 'Learn How to Record Music' },
    { id: 'feature8', icon: 'images/overlay/music-lessons.gif', label: 'Music Lessons', description: 'Enhance Your Skills with Lessons' },
    { id: 'feature9', icon: 'images/overlay/practice-goals.gif', label: 'Practice Goals', description: 'Set and Achieve Your Music Goals' },
    { id: 'feature10', icon: 'images/overlay/music-technology.gif', label: 'Music Technology', description: 'Utilize Technology for Music Production' },
    { id: 'feature11', icon: 'images/overlay/ensemble-practice.gif', label: 'Ensemble Practice', description: 'Practice Playing with a Group' },
    { id: 'feature12', icon: 'images/overlay/composition.gif', label: 'Composition', description: 'Create Your Own Music' },
  ];
  
  const features11 = [
    { id: 'feature1', icon: 'images/overlay/daily-routine.gif', label: 'Daily Routine', description: 'Establish a Healthy Daily Routine' },
    { id: 'feature2', icon: 'images/overlay/skin-care.gif', label: 'Skin Care', description: 'Maintain Healthy Skin' },
    { id: 'feature3', icon: 'images/overlay/hair-care.gif', label: 'Hair Care', description: 'Care for Your Hair Daily' },
    { id: 'feature4', icon: 'images/overlay/mental-health.gif', label: 'Mental Health', description: 'Prioritize Your Mental Well-being' },
    { id: 'feature5', icon: 'images/overlay/physical-health.gif', label: 'Physical Health', description: 'Focus on Your Physical Well-being' },
    { id: 'feature6', icon: 'images/overlay/relaxation-techniques.gif', label: 'Relaxation Techniques', description: 'Learn Ways to Relax' },
    { id: 'feature7', icon: 'images/overlay/mindfulness-practices.gif', label: 'Mindfulness Practices', description: 'Incorporate Mindfulness into Your Life' },
    { id: 'feature8', icon: 'images/overlay/sleep-hygiene.gif', label: 'Sleep Hygiene', description: 'Improve Your Sleep Quality' },
    { id: 'feature9', icon: 'images/overlay/nutrition.gif', label: 'Nutrition', description: 'Eat a Balanced Diet' },
    { id: 'feature10', icon: 'images/overlay/exercise.gif', label: 'Exercise', description: 'Stay Active and Fit' },
    { id: 'feature11', icon: 'images/overlay/hobbies-interests.gif', label: 'Hobbies and Interests', description: 'Pursue Your Passions' },
    { id: 'feature12', icon: 'images/overlay/social-connections.gif', label: 'Social Connections', description: 'Build and Maintain Relationships' },
  ];
  const features12 = [
    { id: 'feature1', icon: 'images/overlay/short-term-goals.gif', label: 'Short-Term Goals', description: 'Set Achievable Short-Term Objectives' },
    { id: 'feature2', icon: 'images/overlay/long-term-goals.gif', label: 'Long-Term Goals', description: 'Plan for Your Future Aspirations' },
    { id: 'feature3', icon: 'images/overlay/personal-goals.gif', label: 'Personal Goals', description: 'Identify Your Personal Aspirations' },
    { id: 'feature4', icon: 'images/overlay/professional-goals.gif', label: 'Professional Goals', description: 'Define Your Career Aspirations' },
    { id: 'feature5', icon: 'images/overlay/financial-goals.gif', label: 'Financial Goals', description: 'Set Targets for Your Finances' },
    { id: 'feature6', icon: 'images/overlay/health-goals.gif', label: 'Health Goals', description: 'Focus on Your Health Objectives' },
    { id: 'feature7', icon: 'images/overlay/relationship-goals.gif', label: 'Relationship Goals', description: 'Nurture Your Relationships' },
    { id: 'feature8', icon: 'images/overlay/educational-goals.gif', label: 'Educational Goals', description: 'Pursue Your Learning Objectives' },
    { id: 'feature9', icon: 'images/overlay/career-goals.gif', label: 'Career Goals', description: 'Plan Your Career Path' },
    { id: 'feature10', icon: 'images/overlay/travel-goals.gif', label: 'Travel Goals', description: 'Explore Your Travel Aspirations' },
    { id: 'feature11', icon: 'images/overlay/creative-goals.gif', label: 'Creative Goals', description: 'Cultivate Your Creative Passions' },
    { id: 'feature12', icon: 'images/overlay/goal-tracking.gif', label: 'Goal Tracking', description: 'Monitor Your Progress Towards Goals' },
  ];
  const features13 = [
    { id: 'feature1', icon: 'images/overlay/relaxation-techniques.gif', label: 'Relaxation Techniques', description: 'Explore Ways to Relax' },
    { id: 'feature2', icon: 'images/overlay/mindfulness-practices.gif', label: 'Mindfulness Practices', description: 'Incorporate Mindfulness into Daily Life' },
    { id: 'feature3', icon: 'images/overlay/physical-activity.gif', label: 'Physical Activity', description: 'Stay Active for Stress Relief' },
    { id: 'feature4', icon: 'images/overlay/healthy-eating.gif', label: 'Healthy Eating', description: 'Nourish Your Body with Healthy Foods' },
    { id: 'feature5', icon: 'images/overlay/sleep-hygiene.gif', label: 'Sleep Hygiene', description: 'Improve Your Sleep Quality' },
    { id: 'feature6', icon: 'images/overlay/time-management.gif', label: 'Time Management', description: 'Manage Your Time Effectively' },
    { id: 'feature7', icon: 'images/overlay/stress-reduction-goals.gif', label: 'Stress Reduction Goals', description: 'Set Goals to Manage Stress' },
    { id: 'feature8', icon: 'images/overlay/social-support.gif', label: 'Social Support', description: 'Build a Supportive Network' },
    { id: 'feature9', icon: 'images/overlay/hobbies-interests.gif', label: 'Hobbies and Interests', description: 'Engage in Activities You Enjoy' },
    { id: 'feature10', icon: 'images/overlay/professional-help.gif', label: 'Professional Help', description: 'Seek Support from Professionals' },
    { id: 'feature11', icon: 'images/overlay/stress-triggers.gif', label: 'Stress Triggers', description: 'Identify Your Stress Triggers' },
    { id: 'feature12', icon: 'images/overlay/positive-thinking.gif', label: 'Positive Thinking', description: 'Cultivate a Positive Mindset' },
  ];
  const features14 = [
    { id: 'feature1', icon: 'images/overlay/recipe-collection.gif', label: 'Recipe Collection', description: 'Organize Your Favorite Recipes' },
    { id: 'feature2', icon: 'images/overlay/meal-prep.gif', label: 'Meal Prep', description: 'Prepare Meals in Advance' },
    { id: 'feature3', icon: 'images/overlay/baking-techniques.gif', label: 'Baking Techniques', description: 'Master the Art of Baking' },
    { id: 'feature4', icon: 'images/overlay/cooking-techniques.gif', label: 'Cooking Techniques', description: 'Enhance Your Cooking Skills' },
    { id: 'feature5', icon: 'images/overlay/kitchen-organization.gif', label: 'Kitchen Organization', description: 'Keep Your Kitchen Tidy and Efficient' },
    { id: 'feature6', icon: 'images/overlay/seasonal-cooking.gif', label: 'Seasonal Cooking', description: 'Cook with Fresh, Seasonal Ingredients' },
    { id: 'feature7', icon: 'images/overlay/special-diets.gif', label: 'Special Diets', description: 'Cook for Dietary Restrictions' },
    { id: 'feature8', icon: 'images/overlay/international-cuisine.gif', label: 'International Cuisine', description: 'Explore Global Cooking Styles' },
    { id: 'feature9', icon: 'images/overlay/cooking-classes.gif', label: 'Cooking Classes', description: 'Learn from the Experts' },
    { id: 'feature10', icon: 'images/overlay/baking-supplies.gif', label: 'Baking Supplies', description: 'Essential Tools for Baking' },
    { id: 'feature11', icon: 'images/overlay/food-presentation.gif', label: 'Food Presentation', description: 'Make Your Dishes Visually Appealing' },
    { id: 'feature12', icon: 'images/overlay/cooking-for-one.gif', label: 'Cooking for One', description: 'Prepare Meals for Yourself' },
  ];
  const features15 = [
    { id: 'feature1', icon: 'images/overlay/linkedin-management.gif', label: 'LinkedIn Management', description: 'Manage Your Professional Profile' },
    { id: 'feature2', icon: 'images/overlay/networking-events.gif', label: 'Networking Events', description: 'Attend Events to Expand Your Network' },
    { id: 'feature3', icon: 'images/overlay/professional-associations.gif', label: 'Professional Associations', description: 'Join Industry Associations' },
    { id: 'feature4', icon: 'images/overlay/industry-conferences.gif', label: 'Industry Conferences', description: 'Participate in Conferences for Networking' },
    { id: 'feature5', icon: 'images/overlay/business-cards.gif', label: 'Business Cards', description: 'Create and Share Professional Cards' },
    { id: 'feature6', icon: 'images/overlay/networking-goals.gif', label: 'Networking Goals', description: 'Set Clear Networking Objectives' },
    { id: 'feature7', icon: 'images/overlay/follow-up-techniques.gif', label: 'Follow-Up Techniques', description: 'Master the Art of Following Up' },
    { id: 'feature8', icon: 'images/overlay/online-networking.gif', label: 'Online Networking', description: 'Leverage Online Platforms for Networking' },
    { id: 'feature9', icon: 'images/overlay/mentorship.gif', label: 'Mentorship', description: 'Build Mentor Relationships' },
    { id: 'feature10', icon: 'images/overlay/networking-skills.gif', label: 'Networking Skills', description: 'Improve Your Networking Abilities' },
    { id: 'feature11', icon: 'images/overlay/elevator-pitch.gif', label: 'Elevator Pitch', description: 'Craft an Effective Elevator Pitch' },
    { id: 'feature12', icon: 'images/overlay/networking-groups.gif', label: 'Networking Groups', description: 'Join and Engage in Networking Groups' },
  ];
  const features16 = [
    { id: 'feature1', icon: 'images/overlay/home-decor.gif', label: 'Home Decor', description: 'Design Your Space' },
    { id: 'feature2', icon: 'images/overlay/furniture-building.gif', label: 'Furniture Building', description: 'Create Custom Furniture' },
    { id: 'feature3', icon: 'images/overlay/craft-projects.gif', label: 'Craft Projects', description: 'Explore Creative Crafts' },
    { id: 'feature4', icon: 'images/overlay/home-repairs.gif', label: 'Home Repairs', description: 'Fix and Maintain Your Home' },
    { id: 'feature5', icon: 'images/overlay/outdoor-projects.gif', label: 'Outdoor Projects', description: 'Enhance Your Outdoor Space' },
    { id: 'feature6', icon: 'images/overlay/upcycling.gif', label: 'Upcycling', description: 'Repurpose and Reuse' },
    { id: 'feature7', icon: 'images/overlay/seasonal-decorations.gif', label: 'Seasonal Decorations', description: 'Decorate for the Seasons' },
    { id: 'feature8', icon: 'images/overlay/clothing-alterations.gif', label: 'Clothing Alterations', description: 'Customize Your Clothes' },
    { id: 'feature9', icon: 'images/overlay/diy-gifts.gif', label: 'DIY Gifts', description: 'Create Handmade Gifts' },
    { id: 'feature10', icon: 'images/overlay/garden-projects.gif', label: 'Garden Projects', description: 'Work on Your Garden' },
    { id: 'feature11', icon: 'images/overlay/tech-projects.gif', label: 'Tech Projects', description: 'Work on Tech DIY Projects' },
    { id: 'feature12', icon: 'images/overlay/art-projects.gif', label: 'Art Projects', description: 'Explore Creative Arts' },
  ];
  
  
  const features17 = [
    { id: 'feature1', icon: 'images/overlay/mental-health-education.gif', label: 'Mental Health Education', description: 'Learn About Mental Health' },
    { id: 'feature2', icon: 'images/overlay/stress-management.gif', label: 'Stress Management', description: 'Manage Stress Effectively' },
    { id: 'feature3', icon: 'images/overlay/mental-health-goals.gif', label: 'Mental Health Goals', description: 'Set Your Mental Health Objectives' },
    { id: 'feature4', icon: 'images/overlay/therapy-counseling.gif', label: 'Therapy and Counseling', description: 'Seek Professional Help' },
    { id: 'feature5', icon: 'images/overlay/support-groups.gif', label: 'Support Groups', description: 'Join Supportive Communities' },
    { id: 'feature6', icon: 'images/overlay/mental-health-resources.gif', label: 'Mental Health Resources', description: 'Access Helpful Resources' },
    { id: 'feature7', icon: 'images/overlay/self-care-practices.gif', label: 'Self-Care Practices', description: 'Practice Self-Care' },
    { id: 'feature8', icon: 'images/overlay/mindfulness.gif', label: 'Mindfulness', description: 'Stay Present and Calm' },
    { id: 'feature9', icon: 'images/overlay/mental-health-advocacy.gif', label: 'Mental Health Advocacy', description: 'Advocate for Mental Health Awareness' },
    { id: 'feature10', icon: 'images/overlay/crisis-management.gif', label: 'Crisis Management', description: 'Handle Mental Health Crises' },
    { id: 'feature11', icon: 'images/overlay/emotional-support.gif', label: 'Emotional Support', description: 'Provide or Seek Emotional Support' },
    { id: 'feature12', icon: 'images/overlay/mental-health-screening.gif', label: 'Mental Health Screening', description: 'Assess Your Mental Health' },
  ];
  const features18 = [
    { id: 'feature1', icon: 'images/overlay/communication.gif', label: 'Communication', description: 'Enhance Communication Skills' },
    { id: 'feature2', icon: 'images/overlay/decision-making.gif', label: 'Decision Making', description: 'Improve Decision-Making Processes' },
    { id: 'feature3', icon: 'images/overlay/team-building.gif', label: 'Team Building', description: 'Build and Strengthen Teams' },
    { id: 'feature4', icon: 'images/overlay/conflict-resolution.gif', label: 'Conflict Resolution', description: 'Resolve Conflicts Effectively' },
    { id: 'feature5', icon: 'images/overlay/leadership-goals.gif', label: 'Leadership Goals', description: 'Set Leadership Objectives' },
    { id: 'feature6', icon: 'images/overlay/motivation-techniques.gif', label: 'Motivation Techniques', description: 'Inspire and Motivate Others' },
    { id: 'feature7', icon: 'images/overlay/delegation.gif', label: 'Delegation', description: 'Delegate Tasks Efficiently' },
    { id: 'feature8', icon: 'images/overlay/strategic-planning.gif', label: 'Strategic Planning', description: 'Plan Strategically for Success' },
    { id: 'feature9', icon: 'images/overlay/time-management.gif', label: 'Time Management', description: 'Master Time Management' },
    { id: 'feature10', icon: 'images/overlay/emotional-intelligence.gif', label: 'Emotional Intelligence', description: 'Enhance Emotional Intelligence' },
    { id: 'feature11', icon: 'images/overlay/mentoring.gif', label: 'Mentoring', description: 'Mentor and Guide Others' },
    { id: 'feature12', icon: 'images/overlay/problem-solving.gif', label: 'Problem Solving', description: 'Solve Problems Effectively' },
  ];
  const features19 = [
    { id: 'feature1', icon: 'images/overlay/speech-writing.gif', label: 'Speech Writing', description: 'Craft Effective Speeches' },
    { id: 'feature2', icon: 'images/overlay/presentation-skills.gif', label: 'Presentation Skills', description: 'Enhance Your Presentation Skills' },
    { id: 'feature3', icon: 'images/overlay/overcoming-fear.gif', label: 'Overcoming Fear', description: 'Conquer the Fear of Public Speaking' },
    { id: 'feature4', icon: 'images/overlay/vocal-techniques.gif', label: 'Vocal Techniques', description: 'Master Your Voice' },
    { id: 'feature5', icon: 'images/overlay/body-language.gif', label: 'Body Language', description: 'Use Body Language Effectively' },
    { id: 'feature6', icon: 'images/overlay/audience-engagement.gif', label: 'Audience Engagement', description: 'Engage Your Audience' },
    { id: 'feature7', icon: 'images/overlay/visual-aids.gif', label: 'Visual Aids', description: 'Incorporate Visual Aids' },
    { id: 'feature8', icon: 'images/overlay/practice-sessions.gif', label: 'Practice Sessions', description: 'Perfect Your Speech Through Practice' },
    { id: 'feature9', icon: 'images/overlay/public-speaking-goals.gif', label: 'Public Speaking Goals', description: 'Set Public Speaking Objectives' },
    { id: 'feature10', icon: 'images/overlay/networking-opportunities.gif', label: 'Networking Opportunities', description: 'Leverage Networking Opportunities' },
    { id: 'feature11', icon: 'images/overlay/speech-delivery.gif', label: 'Speech Delivery', description: 'Deliver Speeches Confidently' },
    { id: 'feature12', icon: 'images/overlay/feedback-improvement.gif', label: 'Feedback and Improvement', description: 'Improve Through Feedback' },
  ];
  const features20 = [
    { id: 'feature1', icon: 'images/overlay/vocabulary-building.gif', label: 'Vocabulary Building', description: 'Expand Your Vocabulary' },
    { id: 'feature2', icon: 'images/overlay/grammar-practice.gif', label: 'Grammar Practice', description: 'Improve Your Grammar Skills' },
    { id: 'feature3', icon: 'images/overlay/speaking-practice.gif', label: 'Speaking Practice', description: 'Enhance Your Speaking Abilities' },
    { id: 'feature4', icon: 'images/overlay/listening-comprehension.gif', label: 'Listening Comprehension', description: 'Boost Your Listening Skills' },
    { id: 'feature5', icon: 'images/overlay/writing-practice.gif', label: 'Writing Practice', description: 'Sharpen Your Writing Skills' },
    { id: 'feature6', icon: 'images/overlay/language-apps.gif', label: 'Language Apps', description: 'Use Apps to Learn Faster' },
    { id: 'feature7', icon: 'images/overlay/language-classes.gif', label: 'Language Classes', description: 'Enroll in Language Classes' },
    { id: 'feature8', icon: 'images/overlay/immersion-techniques.gif', label: 'Immersion Techniques', description: 'Immerse Yourself in the Language' },
    { id: 'feature9', icon: 'images/overlay/language-exchange.gif', label: 'Language Exchange', description: 'Practice with Language Partners' },
    { id: 'feature10', icon: 'images/overlay/cultural-understanding.gif', label: 'Cultural Understanding', description: 'Learn About the Culture' },
    { id: 'feature11', icon: 'images/overlay/pronunciation-practice.gif', label: 'Pronunciation Practice', description: 'Perfect Your Pronunciation' },
    { id: 'feature12', icon: 'images/overlay/language-goals.gif', label: 'Language Goals', description: 'Set Your Language Learning Goals' },
  ];
  const features21 = [
    { id: 'feature1', icon: 'images/overlay/job-search.gif', label: 'Job Search', description: 'Find Your Next Opportunity' },
    { id: 'feature2', icon: 'images/overlay/resume-building.gif', label: 'Resume Building', description: 'Craft a Strong Resume' },
    { id: 'feature3', icon: 'images/overlay/interview-preparation.gif', label: 'Interview Preparation', description: 'Ace Your Interviews' },
    { id: 'feature4', icon: 'images/overlay/skill-development.gif', label: 'Skill Development', description: 'Grow Your Skills' },
    { id: 'feature5', icon: 'images/overlay/networking.gif', label: 'Networking', description: 'Expand Your Professional Network' },
    { id: 'feature6', icon: 'images/overlay/career-goals.gif', label: 'Career Goals', description: 'Set Your Career Path' },
    { id: 'feature7', icon: 'images/overlay/performance-reviews.gif', label: 'Performance Reviews', description: 'Review Your Job Performance' },
    { id: 'feature8', icon: 'images/overlay/continuing-education.gif', label: 'Continuing Education', description: 'Pursue Further Learning' },
    { id: 'feature9', icon: 'images/overlay/work-life-balance.gif', label: 'Work-Life Balance', description: 'Balance Work and Life' },
    { id: 'feature10', icon: 'images/overlay/professional-reading.gif', label: 'Professional Reading', description: 'Stay Informed with Reading' },
    { id: 'feature11', icon: 'images/overlay/career-mentoring.gif', label: 'Career Mentoring', description: 'Find a Career Mentor' },
    { id: 'feature12', icon: 'images/overlay/leadership-skills.gif', label: 'Leadership Skills', description: 'Develop Leadership Abilities' },
  ];
  const features22 = [
    { id: 'feature1', icon: 'images/overlay/project-planning.gif', label: 'Project Planning', description: 'Plan Your Project Effectively' },
    { id: 'feature2', icon: 'images/overlay/risk-management.gif', label: 'Risk Management', description: 'Identify and Mitigate Risks' },
    { id: 'feature3', icon: 'images/overlay/resource-allocation.gif', label: 'Resource Allocation', description: 'Distribute Resources Efficiently' },
    { id: 'feature4', icon: 'images/overlay/budgeting.gif', label: 'Budgeting', description: 'Manage Your Project Budget' },
    { id: 'feature5', icon: 'images/overlay/scheduling.gif', label: 'Scheduling', description: 'Create and Follow Schedules' },
    { id: 'feature6', icon: 'images/overlay/team-coordination.gif', label: 'Team Coordination', description: 'Coordinate with Your Team' },
    { id: 'feature7', icon: 'images/overlay/project-tracking.gif', label: 'Project Tracking', description: 'Track Project Progress' },
    { id: 'feature8', icon: 'images/overlay/stakeholder-communication.gif', label: 'Stakeholder Communication', description: 'Communicate with Stakeholders' },
    { id: 'feature9', icon: 'images/overlay/quality-management.gif', label: 'Quality Management', description: 'Ensure Project Quality' },
    { id: 'feature10', icon: 'images/overlay/project-documentation.gif', label: 'Project Documentation', description: 'Document Project Details' },
    { id: 'feature11', icon: 'images/overlay/change-management.gif', label: 'Change Management', description: 'Handle Project Changes' },
    { id: 'feature12', icon: 'images/overlay/agile-methodologies.gif', label: 'Agile Methodologies', description: 'Implement Agile Approaches' },
  ];
  const features23 = [
    { id: 'feature1', icon: 'images/overlay/financial-statements.gif', label: 'Financial Statements', description: 'Analyze Your Financial Reports' },
    { id: 'feature2', icon: 'images/overlay/ratio-analysis.gif', label: 'Ratio Analysis', description: 'Assess Financial Ratios' },
    { id: 'feature3', icon: 'images/overlay/budget-analysis.gif', label: 'Budget Analysis', description: 'Evaluate Your Budget' },
    { id: 'feature4', icon: 'images/overlay/cash-flow.gif', label: 'Cash Flow Analysis', description: 'Understand Cash Inflows & Outflows' },
    { id: 'feature5', icon: 'images/overlay/investment-analysis.gif', label: 'Investment Analysis', description: 'Analyze Your Investments' },
    { id: 'feature6', icon: 'images/overlay/financial-goals.gif', label: 'Financial Goals', description: 'Set and Track Financial Goals' },
    { id: 'feature7', icon: 'images/overlay/forecasting.gif', label: 'Forecasting', description: 'Predict Financial Trends' },
    { id: 'feature8', icon: 'images/overlay/risk-assessment.gif', label: 'Risk Assessment', description: 'Evaluate Financial Risks' },
    { id: 'feature9', icon: 'images/overlay/profitability-analysis.gif', label: 'Profitability Analysis', description: 'Assess Your Profitability' },
    { id: 'feature10', icon: 'images/overlay/cost-analysis.gif', label: 'Cost Analysis', description: 'Analyze Business Costs' },
    { id: 'feature11', icon: 'images/overlay/performance-metrics.gif', label: 'Performance Metrics', description: 'Measure Financial Performance' },
    { id: 'feature12', icon: 'images/overlay/financial-modeling.gif', label: 'Financial Modeling', description: 'Build Financial Models' },
  ];
  
  return (
    <div>
      <Navigation />
      <Header data={{ /* Add any data needed for the Header component */ }} />
      <div className="two">
        <h1>
          Top Trending Checklists
         {/* <span>Click to Download</span> */}
        </h1>
     
      </div>
      <div>
      <Carousel />
      </div>
           <div className="two">
        <h1>
          Kickstart your day by choosing your checklist
         
        </h1>
      </div>
        <div className='page-wrapper'>
      <div className="card-container">
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
      </div>
    

      <div className="two">
        <h1>
          Checklist categories
        </h1>
      </div>
      <br />
      <div className="feature-lists-container">
      <FeatureList title={<><span>Select what you'd like to focus on<br/>Fitness Routine</span></>} items={features} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Personal Finance</span></>} items={features1} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Health & Wellness</span></>} items={features2} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Work Productivity</span></>} items={features3} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Travel</span></>} items={features4} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Food & Nutrition</span></>} items={features5} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Gardening</span></>} items={features6} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Home Management</span></>} items={features7} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Time Management</span></>} items={features8} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Personal Growth</span></>} items={features9} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Music Practice</span></>} items={features10} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Self Care</span></>} items={features11} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Goal Setting</span></>} items={features12} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Stress Management</span></>} items={features13} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Cooking & Baking</span></>} items={features14} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Professional Networking</span></>} items={features15} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Creative Projects</span></>} items={features16} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Mental Health</span></>} items={features17} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Leadership Skills</span></>} items={features18} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Public Speaking</span></>} items={features19} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Language Learning</span></>} items={features20} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Career Development</span></>} items={features21} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Project Management</span></>} items={features22} />
<FeatureList title={<><span>Select what you'd like to focus on<br/>Financial Planning</span></>} items={features23} />

        
      </div>
        <PricingContainer />
        <HowCanWeHelp />
      <Newsletter />
      <FooterComponent />
    </div>      
    

  );
}

export default Checklist;
