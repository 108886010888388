// FooterComponent.js
import { Footer } from "flowbite-react";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

export function FooterComponent() {
  return (
    <Footer container className="footer-container">
      <div className="footer-content">
        <div className="footer-flex">
          {/* Logo Section */}
          <div className="footer-brand" style={{ color: '#2c5282', fontWeight: 'bold', fontSize: '24px' }}>
            1 Minute Checklist
          </div>
          
          {/* Newsletter Section */}
          <div className="newsletter-container">
            <div className="newsletter-heading">Stay Informed</div>
            <input
              type="email"
              placeholder="Enter email address"
              className="newsletter-input"
              required
            />
            <button className="subscribe-btn">Subscribe</button>
          </div>

          {/* Links Section */}
          <div className="footer-links">
            <div>
              <Footer.Title title="About" className="footer-title" />
              <Footer.LinkGroup className="footer-link-group">
                <Footer.Link href="#" className="footer-link">Flowbite</Footer.Link>
                <Footer.Link href="#" className="footer-link">Tailwind CSS</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Follow us" className="footer-title" />
              <Footer.LinkGroup className="footer-link-group">
                <Footer.Link href="#" className="footer-link">Discord</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" className="footer-title" />
              <Footer.LinkGroup className="footer-link-group">
                <Footer.Link href="#" className="footer-link">Privacy Policy</Footer.Link>
                <Footer.Link href="#" className="footer-link">Terms &amp; Conditions</Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <div className="footer-divider" />
        <div className="footer-bottom">
          <Footer.Copyright href="#" by="Dappl" year={2024} className="footer-copyright" />
          <div className="footer-icons">
            <Footer.Icon href="#" icon={BsFacebook} />
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsTwitter} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
