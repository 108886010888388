import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="header-container">
        <div className="header-left">
          <h2>Welcome to OneMinute Checklist</h2>
          <div className="search-bar">
            <input type="text" placeholder="Search checklists..." />
          </div>
          <button className="custom-button">Get Started</button>
        </div>
        <div className="header-right">
          <div className="illustration">
            {/* Placeholder for 3D illustration */}
            <img src="images/3d-illustration.png" alt="3D Illustration" />
          </div>
        </div>
      </div>
    </header>
  );
};
