import React from 'react';

export const Newsletter = () => {
  return (
    <div className="newsletter-container">
      <div className="newsletter-content">
        <div className="newsletter-left">
          <h3>Got a checklist in mind? Share it with us and help others stay organized!</h3>
          
        </div>
        <div className="newsletter-right">
          <div className="newsletter-form">
            <input
              type="email"
              placeholder="Buying a Bike"
              className="email-input"
            />
            <button className="subscribe-btn">Submit</button>
          </div>
         
        </div>
      </div>
     
    </div>
  );
};
