import React from "react";

const HowCanWeHelp = () => {
  return (
    <div className="b-how-can-we-help">
      <h2 className="b-heading">
        Benefits for <span className="b-highlight">Business</span>
      </h2>
      <div className="b-container">
        <div className="b-card">
          <img
            src="https://via.placeholder.com/100"
            alt="Door delivery"
            className="b-icon"
          />
          <h3 className="b-card-title">Starting the day in the best way</h3>
          <p className="b-card-description">
            We deliver delicious products from our trusted suppliers straight
            to your door up to three days a week. What better way to open your
            door in the morning?
          </p>
        </div>

        <div className="b-card">
          <img
            src="https://via.placeholder.com/100"
            alt="Recycle symbol"
            className="b-icon"
          />
          <h3 className="b-card-title">An easy way to do the right thing</h3>
          <p className="b-card-description">
            It's hard to recycle and make sustainable choices. We make it easy
            to do the right thing by providing return and re-use supply chains
            and sustainable packaging.
          </p>
        </div>
        <div className="b-card">
          <img
            src="https://via.placeholder.com/100"
            alt="Recycle symbol"
            className="b-icon"
          />
          <h3 className="b-card-title">An easy way to do the right thing</h3>
          <p className="b-card-description">
            It's hard to recycle and make sustainable choices. We make it easy
            to do the right thing by providing return and re-use supply chains
            and sustainable packaging.
          </p>
        </div>
        <div className="b-card">
          <img
            src="https://via.placeholder.com/100"
            alt="Recycle symbol"
            className="b-icon"
          />
          <h3 className="b-card-title">An easy way to do the right thing</h3>
          <p className="b-card-description">
            It's hard to recycle and make sustainable choices. We make it easy
            to do the right thing by providing return and re-use supply chains
            and sustainable packaging.
          </p>
        </div>

        <div className="b-card">
          <img
            src="https://via.placeholder.com/100"
            alt="People collaboration"
            className="b-icon"
          />
          <h3 className="b-card-title">
            A collective of small habits leads to a big impact
          </h3>
          <p className="b-card-description">
            Our community of customers are all doing their bit to take care of
            the world around us. Together, our actions are reducing waste one
            delivery at a time.
          </p>
        </div>
      </div>

     
    </div>
  );
};

export default HowCanWeHelp;
